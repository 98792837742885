/* eslint-disable jsx-a11y/control-has-associated-label */
import { LeftOutlined, MinusCircleOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import { Typography, Button, Card, Form, Input, Space, Select, Divider, Switch, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { getClientHost } from 'shared/constants';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Dataset, DatasetsCondition } from 'proto/c60/nn/center/DataSets_pb';
import { TrainingQueryServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { TrainingQuery } from 'proto/c60/nn/center/Detectors_pb';
import {
    ArchitectureDescriptor,
    ImageSize,
    InputChannel,
    InputDescriptor,
    ModelReady,
    TrainSettings,
} from 'proto/nn_training/DetectorTraining_pb';
import WarningInput from '../warning-input/warning-input';

type FormValuesDataType = {
    detectorname: string;
    datasetid: number;
    'architecture.input.size.width': string;
    'architecture.input.size.height': string;
    'architecture.networksize': number;
    'architecture.input.channelsList.sensor': number;
    'architecture.input.channelsList.size': number;
    moresensors: Array<{
        'architecture.input.channelsList.size': number;
        'architecture.input.channelsList.sensor': number;
    }>;
    'trainsetting.readyModel': number;
    'trainsetting.workerCount': string;
    'trainsetting.epochs': string;
    'trainsetting.batch': string;
    'trainsetting.freeze': string;
    'trainsetting.boxgain': number;
    'trainsetting.classgain': number;
    'trainsetting.dflgain': number;
    'trainsetting.weightdecay': number;
    'trainsetting.learnbeginrate': number;
    'trainsetting.learnfinalrate': number;
    'trainsetting.optimizer': number;
    'trainsetting.saveimgproportions': boolean;
    'trainsetting.amp': boolean;
    'trainsetting.deterministic': boolean;
    'trainsetting.patience': string;
};

const CreateLearningPage = (): JSX.Element => {
    const history = useHistory();
    const currentHost = getClientHost();

    const [form] = useForm();
    const [loadingNewQuery, setLoadingNewQuery] = useState<boolean>(false);
    const [datasetData, setDatasetData] = useState<Dataset.AsObject[]>();

    const [datasetidHelp, setDatasetidHelp] = useState<'Выберите датасет!' | ''>('');
    const [datasetidStatus, setDatasetidStatus] = useState<'error' | '' | undefined>('');

    const [detectornameHelp, setDetectornameHelp] = useState<'Введите название!' | ''>('');
    const [detectornameStatus, setDetectornameStatus] = useState<'error' | '' | undefined>('');

    const setDefaultValues = (): void => {
        form.setFieldValue('trainsetting.readyModel', 1);
        form.setFieldValue('trainsetting.workerCount', 0);
        form.setFieldValue('trainsetting.batch', 4);
        form.setFieldValue('trainsetting.boxgain', 7.5);
        form.setFieldValue('trainsetting.classgain', 0.5);
        form.setFieldValue('trainsetting.dflgain', 1.5);
        form.setFieldValue('trainsetting.weightdecay', 0.0005);
        form.setFieldValue('trainsetting.learnbeginrate', 0.01);
        form.setFieldValue('trainsetting.learnfinalrate', 0.01);
        form.setFieldValue('trainsetting.optimizer', 8);
        form.setFieldValue('trainsetting.amp', true);
        form.setFieldValue('trainsetting.deterministic', true);
        form.setFieldValue('trainsetting.saveimgproportions', true);
    };

    const getDatasetData = async (): Promise<void> => {
        if (currentHost) {
            const client = new DatasetsServiceClient(currentHost);
            const datasetList = (
                await client.getInfo(await client.getAllAvailableDatasets(new DatasetsCondition(), {}), {})
            ).toObject().listList;

            setDatasetData(datasetList);
        }
    };

    useEffect(() => {
        setDefaultValues();
        getDatasetData();
    }, []);

    const handleSubmit = async (values: FormValuesDataType): Promise<void> => {
        if (!form?.getFieldValue('detectorname') || form?.getFieldValue('detectorname')?.length === 0) {
            setDetectornameHelp('Введите название!');
            setDetectornameStatus('error');
        }

        if (!form?.getFieldValue('datasetid') || form?.getFieldValue('datasetid')?.length === 0) {
            setDatasetidHelp('Выберите датасет!');
            setDatasetidStatus('error');
        }

        if (
            !(!form?.getFieldValue('datasetid') || form?.getFieldValue('datasetid')?.length === 0) &&
            !(!form?.getFieldValue('datasetid') || form?.getFieldValue('datasetid')?.length === 0)
        ) {
            if (currentHost) {
                setLoadingNewQuery(true);

                const client = new TrainingQueryServiceClient(currentHost);

                const newTrainingQuery = new TrainingQuery();

                const newArchitecture = new ArchitectureDescriptor();
                const newInputDescriptor = new InputDescriptor();
                const newChannelList: Array<InputChannel> = [];
                const newTrainSettings = new TrainSettings();
                const newModelReady = new ModelReady();

                if (values.detectorname) newTrainingQuery.setDetectorname(values.detectorname);
                if (values.datasetid) newTrainingQuery.setDatasetid(values.datasetid);
                if (values['architecture.input.size.height'] && values['architecture.input.size.width']) {
                    newInputDescriptor.setSize(
                        new ImageSize()
                            .setHeight(Number(values['architecture.input.size.height']))
                            .setWidth(Number(values['architecture.input.size.width'])),
                    );
                }
                if (values['architecture.networksize']) {
                    newArchitecture.setNetworksize(Number(values['architecture.networksize']));
                }
                if (
                    values['architecture.input.channelsList.sensor'] &&
                    values['architecture.input.channelsList.size']
                ) {
                    newChannelList.push(
                        new InputChannel()
                            .setSensor(values['architecture.input.channelsList.sensor'])
                            .setSize(values['architecture.input.channelsList.size']),
                    );
                }
                if (values.moresensors) {
                    values.moresensors.forEach((a) =>
                        newChannelList.push(
                            new InputChannel()
                                .setSensor(a['architecture.input.channelsList.sensor'])
                                .setSize(a['architecture.input.channelsList.size']),
                        ),
                    );
                }

                newInputDescriptor.setChannelsList(newChannelList);
                newArchitecture.setInput(newInputDescriptor);

                if (values['trainsetting.amp']) newTrainSettings.setAmp(values['trainsetting.amp']);
                if (values['trainsetting.batch']) newTrainSettings.setBatch(Number(values['trainsetting.batch']));
                if (values['trainsetting.readyModel']) newModelReady.setFormat(values['trainsetting.readyModel']);
                if (values['trainsetting.workerCount']) newTrainSettings.setWorkercount(Number(values['trainsetting.workerCount']));
                if (values['trainsetting.boxgain']) newTrainSettings.setBoxgain(Number(values['trainsetting.boxgain']));
                if (values['trainsetting.classgain'])
                    newTrainSettings.setClassgain(Number(values['trainsetting.classgain']));
                if (values['trainsetting.deterministic'])
                    newTrainSettings.setDeterministic(values['trainsetting.deterministic']);
                if (values['trainsetting.dflgain']) newTrainSettings.setDflgain(Number(values['trainsetting.dflgain']));
                if (values['trainsetting.epochs']) newTrainSettings.setEpochs(Number(values['trainsetting.epochs']));
                if (values['trainsetting.freeze']) newTrainSettings.setFreeze(Number(values['trainsetting.freeze']));
                if (values['trainsetting.learnbeginrate'])
                    newTrainSettings.setLearnbeginrate(Number(values['trainsetting.learnbeginrate']));
                if (values['trainsetting.learnfinalrate'])
                    newTrainSettings.setLearnfinalrate(Number(values['trainsetting.learnfinalrate']));
                if (values['trainsetting.optimizer']) newTrainSettings.setOptimizer(values['trainsetting.optimizer']);
                if (values['trainsetting.patience'])
                    newTrainSettings.setPatience(Number(values['trainsetting.patience']));
                if (values['trainsetting.saveimgproportions'])
                    newTrainSettings.setSaveimgproportions(values['trainsetting.saveimgproportions']);
                if (values['trainsetting.weightdecay'])
                    newTrainSettings.setWeightdecay(Number(values['trainsetting.weightdecay']));

                newTrainingQuery.setTrainsettings(newTrainSettings);

                newTrainingQuery.setArchitecture(newArchitecture);

                console.log(newTrainingQuery);
                try {
                    await client.startQuery(newTrainingQuery, {});
                    setLoadingNewQuery(false);
                    history.push('/learning');
                    notification.success({
                        message: 'Обучение нейросети успешно создано',
                    });
                } catch (e: any) {
                    console.log(e);
                    notification.error({
                        message: 'Не удалось начать обучение нейросети',
                        description: e.message,
                    });
                } finally {
                    setLoadingNewQuery(false);
                }
            }
        }
    };

    return (
        <>
            <div className='create-learning-page'>
                <div className='header-container'>
                    <Button type='default' className='header-button back' onClick={() => history.push('/learning')}>
                        <LeftOutlined className='margin-icon' />
                        Назад
                    </Button>
                    <Button
                        onClick={() => form.submit()}
                        loading={loadingNewQuery}
                        type='primary'
                        className='header-button start'
                    >
                        Начать обучение
                    </Button>
                </div>
                <div className='cards-container'>
                    <Card className='custom-card arch' title='Архитектура'>
                        <Form onFinish={handleSubmit} form={form}>
                            <table className='arch-table space-8'>
                                <tr>
                                    <th colSpan={1}>Название</th>
                                    <td colSpan={1}>
                                        <Form.Item
                                            help={detectornameHelp}
                                            status={detectornameStatus}
                                            name='detectorname'
                                        >
                                            <Input
                                                placeholder='Введите название'
                                                onChange={() => {
                                                    setDetectornameHelp('');
                                                    setDetectornameStatus('');
                                                }}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <table className='arch-table space-10'>
                                <tr>
                                    <th colSpan={1}>Датасет</th>
                                    <td colSpan={1}>
                                        <Form.Item help={datasetidHelp} status={datasetidStatus} name='datasetid'>
                                            <Select
                                                onChange={() => {
                                                    setDatasetidHelp('');
                                                    setDatasetidStatus('');
                                                }}
                                                loading={datasetData?.length === 0}
                                                options={datasetData?.map((dataset) => ({
                                                    label: dataset.title,
                                                    value: dataset.id,
                                                }))}
                                                placeholder='Выбрать датасет'
                                                notFoundContent={
                                                    <div style={{ textAlign: 'center' }}>Нету доступных датасетов</div>
                                                }
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1}>Размер изображений</th>
                                    <td colSpan={1}>
                                        <Space className='compact'>
                                            <WarningInput
                                                forminstance={form}
                                                formname='architecture.input.size.width'
                                                placeholder='32'
                                                type='number'
                                                addonBefore='Ширина'
                                            />
                                            <WarningInput
                                                forminstance={form}
                                                formname='architecture.input.size.height'
                                                placeholder='32'
                                                type='number'
                                                addonBefore='Высота'
                                            />
                                        </Space>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1}>Размер модели</th>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='architecture.networksize'>
                                            <Select
                                                placeholder='Выбрать размер'
                                                options={[
                                                    { label: 'Минимальный (nano)', value: 1 },
                                                    { label: 'Маленький (small)', value: 2 },
                                                    { label: 'Средний (medium)', value: 3 },
                                                    { label: 'Большой (large)', value: 4 },
                                                    { label: 'Максимальный (XL)', value: 5 },
                                                ]}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <Divider />
                            <table className='arch-table space-10'>
                                <tr>
                                    <th colSpan={1}>Используемый сенсор</th>
                                    <td colSpan={1} className='default-space'>
                                        <Space>
                                            <Form.Item noStyle name='architecture.input.channelsList.sensor'>
                                                <Select
                                                    options={[
                                                        { label: 'Без сенсора', value: 0 },
                                                        { label: 'Камера', value: 1 },
                                                        { label: 'Тепловизор', value: 2 },
                                                        { label: 'Лидар', value: 3 },
                                                        { label: 'Радар', value: 4 },
                                                    ]}
                                                    placeholder='Выбрать сенсор'
                                                />
                                            </Form.Item>

                                            <Form.Item noStyle name='architecture.input.channelsList.size'>
                                                <Select
                                                    options={[
                                                        { label: 'RGB', value: 3 },
                                                        { label: 'Grayscale', value: 1 },
                                                    ]}
                                                    placeholder='Цветовая модель'
                                                />
                                            </Form.Item>
                                        </Space>
                                    </td>
                                </tr>
                                <Form.List name='moresensors'>
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <tr>
                                                    <th colSpan={1} />
                                                    <td colSpan={1} className='new-space'>
                                                        <Space>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                            <Form.Item
                                                                noStyle
                                                                {...restField}
                                                                name={[name, 'architecture.input.channelsList.sensor']}
                                                            >
                                                                <Select
                                                                    options={[
                                                                        { label: 'Без сенсора', value: 0 },
                                                                        { label: 'Камера', value: 1 },
                                                                        { label: 'Тепловизор', value: 2 },
                                                                        { label: 'Лидар', value: 3 },
                                                                        { label: 'Радар', value: 4 },
                                                                    ]}
                                                                    placeholder='Выбрать сенсор'
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                noStyle
                                                                {...restField}
                                                                name={[name, 'architecture.input.channelsList.size']}
                                                            >
                                                                <Select
                                                                    options={[
                                                                        { label: 'RGB', value: 3 },
                                                                        { label: 'Grayscale', value: 1 },
                                                                    ]}
                                                                    placeholder='Цветовая модель'
                                                                />
                                                            </Form.Item>
                                                        </Space>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <th colSpan={1} />
                                                <td colSpan={1}>
                                                    <Form.Item>
                                                        <Button
                                                            className='add-sensor-button'
                                                            type='default'
                                                            onClick={() => add()}
                                                            block
                                                            icon={<VideoCameraAddOutlined />}
                                                        >
                                                            Добавить сенсор
                                                        </Button>
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </Form.List>
                            </table>
                        </Form>
                    </Card>
                    <Card className='custom-card process' title='Настройки процесса обучения'>
                        <Form onFinish={handleSubmit} form={form}>
                            <table className='process-table space-8'>
                                <tr>
                                    <th colSpan={1}>Параметры</th>
                                    <td colSpan={1}>
                                        <Typography.Text>Тип</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.readyModel'>
                                            <Select
                                                options={[
                                                    { label: 'yolo', value: 0 },
                                                    { label: 'tfliteFloatRGB', value: 1 },
                                                ]}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Количество параллельных потоков</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.workerCount'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Количество эпох для обучения</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.epochs'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Количество изображений в пакете</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.batch'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Количество замороженных слоёв</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.freeze'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Коэффициент значимости точности коробки</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.boxgain'>
                                            <Input type='number' placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Коэффициент значимости правильного класса</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.classgain'>
                                            <Input type='number' placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Коэффициент dfl</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.dflgain'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Интенсивность изменения весов</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.weightdecay'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Скорость обучения в начале lr0</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.learnbeginrate'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Скорость обучения в конце lr1</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.learnfinalrate'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Алгоритм управления оптимизацией</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.optimizer'>
                                            <Select
                                                options={[
                                                    { label: 'auto', value: 8 },
                                                    { label: 'Без оптимизации', value: 0 },
                                                    { label: 'SGD', value: 1 },
                                                    { label: 'Adam', value: 2 },
                                                    { label: 'Adamax', value: 3 },
                                                    { label: 'AdamW', value: 4 },
                                                    { label: 'NAdam', value: 5 },
                                                    { label: 'RAdam', value: 6 },
                                                    { label: 'RMSProp', value: 7 },
                                                ]}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <table className='process-table space-18'>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Сохранять пропорции изображения</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.saveimgproportions'>
                                            <Switch defaultChecked />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Автоматическая подстройка точности</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.amp'>
                                            <Switch defaultChecked />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Режим детерминированности</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.deterministic'>
                                            <Switch defaultChecked />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <table className='process-table space-10'>
                                <tr>
                                    <th colSpan={1}>Триггер завершения обучения</th>
                                    <td colSpan={1}>
                                        <Typography.Text>Количество эпох без прогресса</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.patience'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default React.memo(CreateLearningPage);
