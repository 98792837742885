// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { LeftOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import { Col, Row } from 'antd/lib/grid';

import { Project } from 'reducers';
import ActionsButton from 'components/projects-page/actions-button-list';

interface DetailsComponentProps {
    projectInstance: Project;
}

export default function ProjectTopBar(props: DetailsComponentProps): JSX.Element {
    const { projectInstance } = props;

    const history = useHistory();

    return (
        <Row className='cvat-task-top-bar' justify='space-between' align='middle'>
            <Col>
                <Button
                    className='cvat-back-to-projects-button'
                    onClick={() => history.push('/projects')}
                    type='default'
                    size='middle'
                    >
                        <LeftOutlined />
                        Вернуться к проектам
                </Button>
            </Col>
            <div className='cvat-project-top-bar-actions'>
                <ActionsButton projectInstance={projectInstance} />
                {/* <Dropdown
                    destroyPopupOnHide
                    trigger={['click']}
                    overlay={<ActionsMenu projectInstance={projectInstance} />}
                >
                    <Button size='middle' className='cvat-project-page-actions-button'>
                        <Text className='cvat-text-color'>Действия</Text>
                        <MoreOutlined className='cvat-menu-icon' />
                    </Button>
                </Dropdown> */}
                {/* <Button
                    size='middle'
                    type='primary'
                    className='cvat-project-top-bar-create-dataset-button'
                    onClick={() => {
                        dispatch(exportActions.openExportDatasetModal(projectInstance));
                    }}
                >
                    <PictureOutlined />
                    <Text className='cvat-text-color'>Создать датасет</Text>
                </Button> */}
            </div>
        </Row>
    );
}
