// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { SerializedLabel, SerializedAttribute } from 'cvat-core-wrapper';

export interface SkeletonConfiguration {
    type: 'skeleton';
    svg: string;
    sublabels: SerializedLabel[];
}

export type LabelOptColor = SerializedLabel;

let id = 0;

function validateParsedAttribute(attr: SerializedAttribute): void {
    if (typeof attr.name !== 'string') {
        throw new Error(`Тип имени атрибута должен быть строкой. Получено значение ${attr.name}`);
    }

    if (!['number', 'undefined'].includes(typeof attr.id)) {
        throw new Error(
            `Атрибут: "${attr.name}". Тип идентификатора атрибута должен быть числом или неопределенным. Получено значение ${attr.id}`,
        );
    }

    if (!['checkbox', 'number', 'text', 'radio', 'select'].includes((attr.input_type || '').toLowerCase())) {
        throw new Error(`Атрибут: "${attr.name}". Неизвестный тип ввода: ${attr.input_type}`);
    }

    if (typeof attr.mutable !== 'boolean') {
        throw new Error(`Атрибут: "${attr.name}". Изменяемый флаг должен быть логическим значением. Получено значение ${attr.mutable}`);
    }

    if (!Array.isArray(attr.values)) {
        throw new Error(`Атрибут: "${attr.name}". Значения атрибутов должны быть массивом. Получен тип ${typeof attr.values}`);
    }

    if (!attr.values.length) {
        throw new Error(`Атрибут: "${attr.name}". Массив значений атрибутов не должен быть пустым`);
    }

    for (const value of attr.values) {
        if (typeof value !== 'string') {
            throw new Error(`Атрибут: "${attr.name}". Каждое значение должно быть строкой. Получено значение ${value}`);
        }
    }
}

export function validateParsedLabel(label: SerializedLabel): void {
    if (typeof label.name !== 'string') {
        throw new Error(`Тип имени метки должен быть строкой. Получено значение ${label.name}`);
    }

    if (!['number', 'undefined'].includes(typeof label.id)) {
        throw new Error(
            `Метка "${label.name}". Тип идентификатора метки должен быть только числом или неопределенным. Получено значение ${label.id}`,
        );
    }

    if (label.color && typeof label.color !== 'string') {
        throw new Error(`Метка "${label.name}". Цвет метки должен быть строкой. Получено ${typeof label.color}`);
    }

    if (label.color && !label.color.match(/^#[0-9a-fA-F]{6}$|^$/)) {
        throw new Error(
            `Метка "${label.name}". ` +
                `Тип цвета метки должен быть только допустимой строкой цвета. Получено значение ${label.color}`,
        );
    }

    if (!Array.isArray(label.attributes)) {
        throw new Error(`Метка "${label.name}". Атрибуты должны быть массивом. Получен тип ${typeof label.attributes}`);
    }

    for (const attr of label.attributes) {
        validateParsedAttribute(attr);
    }
}

export function idGenerator(): number {
    return --id;
}

export function equalArrayHead(arr1: string[], arr2: string[]): boolean {
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export function toSVGCoord(svg: SVGSVGElement, coord: number[], raiseError = false): number[] {
    const result = [];
    const ctm = svg.getScreenCTM();

    if (!ctm) {
        if (raiseError) throw new Error('Экран CTM имеет значение null');
        return coord;
    }

    const inversed = ctm.inverse();
    if (!inversed) {
        if (raiseError) throw new Error('Обратный экран CTM имеет значение null');
        return coord;
    }

    for (let i = 0; i < coord.length; i += 2) {
        let point = svg.createSVGPoint();
        point.x = coord[i];
        point.y = coord[i + 1];
        point = point.matrixTransform(inversed);
        result.push(point.x, point.y);
    }

    return result;
}

export function fromSVGCoord(svg: SVGSVGElement, coord: number[], raiseError = false): number[] {
    const result = [];
    const ctm = svg.getScreenCTM();
    if (!ctm) {
        if (raiseError) throw new Error('Обратный экран CTM имеет значение null');
        return coord;
    }

    for (let i = 0; i < coord.length; i += 2) {
        let point = svg.createSVGPoint();
        point.x = coord[i];
        point.y = coord[i + 1];
        point = point.matrixTransform(ctm);
        result.push(point.x, point.y);
    }

    return result;
}
