// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Text from 'antd/lib/typography/Text';

import CreateProjectContentDataset from './create-project-content-dataset';

function CreateProjectPageComponentDataset(): JSX.Element {
    return (
        <div className='cvat-create-project-form-wrapper'>
            <Text className='cvat-title'>Создать проект из датасета</Text>
            <CreateProjectContentDataset />
        </div>
    );
}

export default React.memo(CreateProjectPageComponentDataset);
