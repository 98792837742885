// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import ClassificatorViewerItem from './classificator-viewer-item';
import { LabelOptColor } from './common';

interface ClassificatorViewerProps {
    labels: LabelOptColor[];
    onDelete: (label: LabelOptColor) => void;
}

function ClassificatorViewer(props: ClassificatorViewerProps): JSX.Element {
    const { onDelete, labels } = props;
    const list = [];
    for (const label of labels) {
        list.push(<ClassificatorViewerItem onDelete={onDelete} label={label} key={label.id} color={label.color} />);
    }

    return <div className='classificator-viewer' style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>{list}</div>;
}

export default React.memo(ClassificatorViewer);
