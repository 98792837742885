// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Typography } from 'antd';
import { LoadingOutlined, PictureOutlined } from '@ant-design/icons';
import { CombinedState } from 'reducers';
import { deleteProjectAsync } from 'actions/projects-actions';
import { exportActions } from 'actions/export-actions';
import { importActions } from 'actions/import-actions';
import { useHistory } from 'react-router';

interface Props {
    projectInstance: any;
}

const ProjectActionsButtonComponent: React.FC<Props> = ({ projectInstance }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const exportBackupIsActive = useSelector((state: CombinedState) => (
        state.export.projects.backup.current[projectInstance.id]
    ));

    const onDeleteProject = useCallback(() => {
        Modal.confirm({
            title: `Проект ${projectInstance.name} будет удален`,
            content: 'Все данные (изображения, аннотации) будут потеряны. Продолжить?',
            className: 'cvat-modal-confirm-remove-project',
            onOk: () => dispatch(deleteProjectAsync(projectInstance)),
            okButtonProps: { type: 'primary', danger: true },
            cancelText: 'Отменить',
            okText: 'Удалить',
        });
    }, [projectInstance, dispatch]);

    const menuItems = [
        { key: 'import-dataset', label: 'Импорт датасета', onClick: () => dispatch(importActions.openImportDatasetModal(projectInstance)) },
        {
            key: 'backup-project',
            label: 'Бэкап проекта',
            disabled: exportBackupIsActive,
            onClick: () => dispatch(exportActions.openExportBackupModal(projectInstance)),
            icon: exportBackupIsActive && <LoadingOutlined id='cvat-export-project-loading' />,
        },
        {
            key: 'view-analytics',
            label: 'Аналитика',
            onClick: () => history.push(`/projects/${projectInstance.id}/analytics`),
        },
        {
            key: 'set-webhooks',
            label: 'Настройка WebHooks',
            onClick: () => history.push(`/projects/${projectInstance.id}/webhooks`),
        },
        { key: 'delete', label: 'Удалить', onClick: onDeleteProject },
        {
            key: 'create-dataset',
            label: 'Создать датасет',
            onClick: () => dispatch(exportActions.openExportDatasetModal(projectInstance)),
            icon: <PictureOutlined />,
            isPrimary: true,
        },
    ];

    return (
        <div>
            {menuItems.map(({ key, label, onClick, disabled, icon, isPrimary = false }) => (
                <Button
                    key={key}
                    onClick={onClick}
                    disabled={disabled}

                    type={isPrimary ? 'primary' : 'default'}
                    style={{ marginLeft: '10px' }}
                    icon={icon}
                >
                    {label}
                </Button>
            ))}
        </div>
    );
};

export default React.memo(ProjectActionsButtonComponent);
