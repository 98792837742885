// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2022-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
    Form, Modal, notification, Button,
} from 'antd';
import Text from 'antd/lib/typography/Text'

import { CombinedState, StorageLocation } from 'reducers';
import { exportActions, exportDatasetAsync } from 'actions/export-actions';
import {
    Dumper, Job, Project, Storage, StorageData, Task,
} from 'cvat-core-wrapper';

import { getClientHost } from 'shared/constants';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';
import { CreateYoloDatasetParams, Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { ObjectRef } from 'proto/Common_pb';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { createEnhancedClient } from 'utils/grpc';

import CreateDataset from 'components/export-dataset/create-dataset';
import ExportDataset from 'components/export-dataset/export-dataset';

type FormValues = {
    selectedFormat: string | undefined;
    saveImages: boolean;
    customName: string | undefined;
    targetStorage: StorageData;
    useProjectTargetStorage: boolean;
    exportDatasetParams: Dataset.AsObject;
};

const initialValues: FormValues = {
    selectedFormat: undefined,
    saveImages: false,
    customName: undefined,
    targetStorage: {
        location: StorageLocation.LOCAL,
        cloudStorageId: undefined,
    },
    useProjectTargetStorage: true,
    exportDatasetParams: {},
};

function ExportDatasetModal(props: StateToProps): JSX.Element {
    const { dumpers, instance, current } = props;

    const currentHost = getClientHost();

    const [form] = Form.useForm();

    const [instanceType, setInstanceType] = useState('');

    const [useDefaultTargetStorage, setUseDefaultTargetStorage] = useState(true);
    const [targetStorage, setTargetStorage] = useState<StorageData>({
        location: StorageLocation.LOCAL,
    });
    const [defaultStorageLocation, setDefaultStorageLocation] = useState(StorageLocation.LOCAL);
    const [defaultStorageCloudId, setDefaultStorageCloudId] = useState<number>();

    // Формат датасета
    const [datasetFormat, setDatasetFormat] = useState<string>('YOLO 1.1');

    // Индикация загрузки на кнопке Создать, во время выполнения запроса gRPC
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    const backState = useSelector((state: CombinedState) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (instance instanceof Project) {
            setInstanceType(`проекта ${instance.name}`);
        } else if (instance instanceof Task || instance instanceof Job) {
            if (instance instanceof Task) {
                setInstanceType(`задачи ${instance.name}`);
            } else {
                setInstanceType(`разметки #${instance.id}`);
            }
            if (instance.mode === 'interpolation' && instance.dimension === '2d') {
                form.setFieldsValue({ selectedFormat: 'CVAT for video 1.1' });
            } else if (instance.mode === 'annotation' && instance.dimension === '2d') {
                form.setFieldsValue({ selectedFormat: 'CVAT for images 1.1' });
            }
        }
    }, [instance]);

    useEffect(() => {
        if (instance) {
            setDefaultStorageLocation(instance.targetStorage.location);
            setDefaultStorageCloudId(instance.targetStorage.cloudStorageId);
        }
    }, [instance]);

    const closeModal = (): void => {
        setUseDefaultTargetStorage(true);
        setTargetStorage({ location: StorageLocation.LOCAL });
        form.resetFields();
        dispatch(exportActions.closeExportDatasetModal(instance));
        setDatasetFormat('YOLO 1.1');
    };

    const handleExport = useCallback(
        (values: FormValues): void => {
            dispatch(
                exportDatasetAsync(
                    instance,
                    values.selectedFormat as string,
                    values.saveImages,
                    useDefaultTargetStorage,
                    useDefaultTargetStorage ?
                        new Storage({
                            location: defaultStorageLocation,
                            cloudStorageId: defaultStorageCloudId,
                        }) :
                        new Storage(targetStorage),
                    values.customName ? `${values.customName}.zip` : undefined,
                ),
            );
            closeModal();
            const resource = values.saveImages ? 'Датасета' : 'Аннотации';
            notification.info({
                message: `Запущен экспорт ${resource}`,
                description:
                    `Запущен экспорт ${resource} для ${instanceType}. ` +
                    `Загрузка ${resource} начнется, когда архив будет готов.`,
                className: `cvat-notification-notice-export-${instanceType.split(' ')[0]}-start`,
            });
        },
        [instance, instanceType, useDefaultTargetStorage, defaultStorageLocation, defaultStorageCloudId, targetStorage],
    );

    const handleCreate = useCallback(
        (values: FormValues): void => {
            // Добавляем в initialValues информацию по название датасета для создания Датасета
            initialValues.exportDatasetParams.title = values.customName?.trim();

            // Вычисляем общее количество изображений в Проекте
            const imageCount = backState.tasks.current.reduce(
                (accumulator, currentValue) => accumulator + currentValue.size,
                0,
            );

            // Если проект не содержит изображения - выводим ошибку ELSE Создаем датасет
            if (imageCount === 0) {
                notification.error({
                    message: 'Создание нового датасета',
                    description: 'Ошибка создания датасета: нет ни одного изображения.',
                });
            } else {
                const DatasetParams = initialValues.exportDatasetParams;
                const DatasetAugmentation = initialValues.exportDatasetParams.augmentation;

                // new YoloAugmentationConfig()
                const augmentation = DatasetAugmentation ?
                    new YoloAugmentationConfig()
                        .setHsvH(DatasetAugmentation.hsvH)
                        .setHsvS(DatasetAugmentation.hsvS)
                        .setHsvV(DatasetAugmentation.hsvV)
                        .setDegrees(DatasetAugmentation.degrees)
                        .setTranslate(DatasetAugmentation.translate)
                        .setScale(DatasetAugmentation.scale)
                        .setShear(DatasetAugmentation.shear)
                        .setPerspective(DatasetAugmentation.perspective)
                        .setFlipud(DatasetAugmentation.flipud)
                        .setFliplr(DatasetAugmentation.fliplr)
                        .setMosaic(DatasetAugmentation.mosaic)
                        .setMixup(DatasetAugmentation.mixup)
                        .setCopyPaste(DatasetAugmentation.copyPaste) :
                    undefined;

                // new Dataset()
                const datasetConfig = new Dataset()
                    .setTitle(String(DatasetParams.title))
                    .setFormat(Number(DatasetParams.format))
                    .setAuthortitle(String(DatasetParams.authortitle))
                    .setCvatAuthorId(Number(DatasetParams.cvatAuthorId))
                    .setCvatProjectId(Number(DatasetParams.cvatProjectId))
                    .setCvatProjectname(String(DatasetParams.cvatProjectname))
                    .setAugmentation(augmentation);
                if (DatasetParams.organization) {
                    datasetConfig.setOrganization(
                        new ObjectRef()
                            .setId(Number(DatasetParams.organization?.id))
                            .setTitle(String(DatasetParams.organization?.title)),
                    );
                }

                if (
                    Object.prototype.hasOwnProperty.call(DatasetParams, 'trainsetimages') &&
                    Object.prototype.hasOwnProperty.call(DatasetParams, 'validationsetimages') &&
                    Object.prototype.hasOwnProperty.call(DatasetParams, 'testsetimages')
                ) {
                    datasetConfig
                        .setTrainsetimages(DatasetParams.trainsetimages!)
                        .setValidationsetimages(DatasetParams.validationsetimages!)
                        .setTestsetimages(DatasetParams.testsetimages!);
                }

                // ! Создаем датасет gRPC
                (async () => {
                    setConfirmLoading(true);

                    if (currentHost) {
                        const client = createEnhancedClient(DatasetsServiceClient);
                        await client
                            .createYoloDatasetFromProject(
                                new CreateYoloDatasetParams()
                                    .setCvatprojectid(Number(DatasetParams.cvatProjectId))
                                    .setDatasetconfig(datasetConfig),
                                {},
                            )
                            .then(() => {
                                setConfirmLoading(false);
                                notification.success({
                                    message: 'Создание нового датасета',
                                    description: 'Датасет был успешно создан.',
                                });

                                closeModal();
                            })
                            .catch((error) => {
                                setConfirmLoading(false);

                                notification.error({
                                    message: 'Создание нового датасета',
                                    description: 'Ошибка создания датасета.',
                                });

                                console.error(error);
                            });
                    }
                })();

                notification.info({
                    message: 'Создание нового датасета',
                    description:
                        'Запущен процесс создание нового датасета. Не закрывайте ' +
                        'страницу, пока не появится сообщение о завершении процесса.',
                });
            }
        },
        [instance, instanceType, useDefaultTargetStorage, defaultStorageLocation, defaultStorageCloudId, targetStorage],
    );

    return (
        <Modal
            title={<Text strong>{`Экспортировать ${instanceType} как датасет`}</Text>}
            open={!!instance}
            onCancel={closeModal}
            onOk={() => form.submit()}
            footer={
                datasetFormat === 'YOLO 1.1' ?
                    [
                        <Button key='Ok' type='primary' onClick={() => form.submit()} loading={confirmLoading}>
                            Создать
                        </Button>,
                        <Button key='Cancel' onClick={closeModal}>
                            Отменить
                        </Button>,
                    ] :
                    [
                        <Button key='Ok' type='primary' onClick={() => form.submit()}>
                            Экспортировать
                        </Button>,
                        <Button key='Cancel' onClick={closeModal}>
                            Отменить
                        </Button>,
                    ]
            }
            className={`cvat-modal-export-${instanceType.split(' ')[0]}`}
            destroyOnClose
            width={1124}
            afterClose={() => {
                setDatasetFormat('YOLO 1.1');
                setConfirmLoading(false);
            }}
        >
            {datasetFormat === 'YOLO 1.1' ? (
                <CreateDataset
                    form={form}
                    initialValues={initialValues}
                    handleCreate={handleCreate}
                    dumpers={dumpers}
                    instance={instance}
                    current={current}
                    datasetFormat={datasetFormat}
                    setDatasetFormat={setDatasetFormat}
                    backState={backState}
                />
            ) : (
                <ExportDataset
                    form={form}
                    initialValues={initialValues}
                    handleExport={handleExport}
                    dumpers={dumpers}
                    instance={instance}
                    current={current}
                    datasetFormat={datasetFormat}
                    setDatasetFormat={setDatasetFormat}
                />
            )}
        </Modal>
    );
}

interface StateToProps {
    dumpers: Dumper[];
    instance: Project | Task | Job | null;
    current: string[];
}

function mapStateToProps(state: CombinedState): StateToProps {
    const { instanceType } = state.export;
    const instance = !instanceType ?
        null :
        state.export[`${instanceType}s` as 'projects' | 'tasks' | 'jobs'].dataset.modalInstance;

    return {
        instance,
        current: !instanceType ?
            [] :
            state.export[`${instanceType}s` as 'projects' | 'tasks' | 'jobs'].dataset.current[instance.id],
        dumpers: state.formats.annotationFormats.dumpers,
    };
}

export default connect(mapStateToProps)(ExportDatasetModal);
