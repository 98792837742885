/**
 * @fileoverview gRPC-Web generated client stub for grpc.c60.nn.center
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.2
// source: c60/nn/center/DatasetClasses.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as Common_pb from '../../../Common_pb'; // proto import: "Common.proto"
import * as c60_nn_center_DatasetClasses_pb from '../../../c60/nn/center/DatasetClasses_pb'; // proto import: "c60/nn/center/DatasetClasses.proto"


export class DClassServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetAll = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DClassService/getAll',
    grpcWeb.MethodType.UNARY,
    Common_pb.StringSearch,
    Common_pb.ObjectsId,
    (request: Common_pb.StringSearch) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAll(
    request: Common_pb.StringSearch,
    metadata?: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAll(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAll(
    request: Common_pb.StringSearch,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DClassService/getAll',
        request,
        metadata || {},
        this.methodDescriptorgetAll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DClassService/getAll',
    request,
    metadata || {},
    this.methodDescriptorgetAll);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DClassService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    c60_nn_center_DatasetClasses_pb.DClassList,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    c60_nn_center_DatasetClasses_pb.DClassList.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata?: grpcWeb.Metadata | null): Promise<c60_nn_center_DatasetClasses_pb.DClassList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_DatasetClasses_pb.DClassList) => void): grpcWeb.ClientReadableStream<c60_nn_center_DatasetClasses_pb.DClassList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_DatasetClasses_pb.DClassList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DClassService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DClassService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptorgetCvatJsonInfo = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DClassService/getCvatJsonInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.StringMsg,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.StringMsg.deserializeBinary
  );

  getCvatJsonInfo(
    request: Common_pb.ObjectsId,
    metadata?: grpcWeb.Metadata | null): Promise<Common_pb.StringMsg>;

  getCvatJsonInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.StringMsg) => void): grpcWeb.ClientReadableStream<Common_pb.StringMsg>;

  getCvatJsonInfo(
    request: Common_pb.ObjectsId,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.StringMsg) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DClassService/getCvatJsonInfo',
        request,
        metadata || {},
        this.methodDescriptorgetCvatJsonInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DClassService/getCvatJsonInfo',
    request,
    metadata || {},
    this.methodDescriptorgetCvatJsonInfo);
  }

  methodDescriptorinsert = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DClassService/insert',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_DatasetClasses_pb.DClass,
    c60_nn_center_DatasetClasses_pb.DClass,
    (request: c60_nn_center_DatasetClasses_pb.DClass) => {
      return request.serializeBinary();
    },
    c60_nn_center_DatasetClasses_pb.DClass.deserializeBinary
  );

  insert(
    request: c60_nn_center_DatasetClasses_pb.DClass,
    metadata?: grpcWeb.Metadata | null): Promise<c60_nn_center_DatasetClasses_pb.DClass>;

  insert(
    request: c60_nn_center_DatasetClasses_pb.DClass,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_DatasetClasses_pb.DClass) => void): grpcWeb.ClientReadableStream<c60_nn_center_DatasetClasses_pb.DClass>;

  insert(
    request: c60_nn_center_DatasetClasses_pb.DClass,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_DatasetClasses_pb.DClass) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DClassService/insert',
        request,
        metadata || {},
        this.methodDescriptorinsert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DClassService/insert',
    request,
    metadata || {},
    this.methodDescriptorinsert);
  }

  methodDescriptordelete = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DClassService/delete',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.ObjectsId,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  delete(
    request: Common_pb.ObjectsId,
    metadata?: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DClassService/delete',
        request,
        metadata || {},
        this.methodDescriptordelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DClassService/delete',
    request,
    metadata || {},
    this.methodDescriptordelete);
  }

  methodDescriptorcheckUniqTitle = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DClassService/checkUniqTitle',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_DatasetClasses_pb.DClass,
    Common_pb.Check,
    (request: c60_nn_center_DatasetClasses_pb.DClass) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkUniqTitle(
    request: c60_nn_center_DatasetClasses_pb.DClass,
    metadata?: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkUniqTitle(
    request: c60_nn_center_DatasetClasses_pb.DClass,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkUniqTitle(
    request: c60_nn_center_DatasetClasses_pb.DClass,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DClassService/checkUniqTitle',
        request,
        metadata || {},
        this.methodDescriptorcheckUniqTitle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DClassService/checkUniqTitle',
    request,
    metadata || {},
    this.methodDescriptorcheckUniqTitle);
  }

}

